@use "../../../theme/theme";

.popup-main {
  background: theme.$white-color;
  width: 30%;
  min-width: 470px;
  position: relative;
  padding: 15px;

  .--title-head {
    max-width: 90%;
  }

  padding: 12px 12px 16px 12px;
  max-height: 100vh;
  overflow: auto;

  .--header {
    display: flex;
    margin-left: 10px;

    .close-action-item-popup {
      position: absolute;
      right: 20px;
      top: 20px;
      cursor: pointer;
    }
  }

  .--contentbody {
    display: flex;
    flex-direction: column;
    margin: 10px 10px 20px 10px;

    .--formItem {
      margin: 10px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;

      label {
        display: block;
        margin: 5px 5px 5px 0px;
        color: theme.$header-color;
      }

      input {
        // border: 1px solid theme.$border-color;
        // border-radius: 4px;
        width: 100%;
        padding: 5px;
        outline: none !important;
      }

      .dropdown-main {
        width: 100%;
      }
    }
  }

  .--footer {
    display: flex;
    flex-direction: row-reverse;
    margin-right: 10px;
    gap: 10px;

    .--testcancel {
      background: theme.$header-color;
    }
  }

  .--modifiedFooter {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-left: 10px;

    .--saveAndClose {
      display: flex;
      flex-direction: row-reverse;
      margin-right: 10px;
      gap: 10px;
    }
  }
}