@use '../../../../theme/theme';

$managerCardHeight: 60px;

.generateReport {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 2vw 0 2vw;
  gap: 1vw;

  &-header {
    padding: 2vh 0;
    border-bottom: 1px solid theme.$border-color;
  }

  &-input {
    background: #ffffff;
    border: 1px solid theme.$border-color;
    align-items: center;
    display: inline-flex;
    padding: 1.6vh;
    outline: none;
    width: 18vw;
  }

  &-separator {
    display: flex;
    margin: 1vh 0;
    gap: 10px;
    align-items: center;
  }

  &-divider {
    border-bottom: 1px solid theme.$border-color;
    margin: 2vh 0;
    width: 100%;
  }

  &-section {
    margin: 1vh 0;

    &-header {
      margin-bottom: 3vh;
    }

    &-checkbox {
      flex-direction: row;

      &-single {
        display: inline-flex;
        gap: 0.5vw;
        margin: 0px 20px 20px 0;
        width: 22%;
      }

      &-viewOnly {
        display: inline-flex;
        gap: 0.5vw;
        margin: 0px 20px 20px 0;
        width: 30%;
      }

      &-list {
        display: inline-flex;
        flex-wrap: wrap;
        width: 100%;
      }
    }
  }

  &-viewAdvancedSearch {
    display: flex;
    align-items: center;
    gap: 0.5vw;
  }

  &-advancedSearch {
    display: flex;
    align-items: center;
    gap: 0.5vw;
    color: theme.$base-color;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    cursor: pointer;
    width: fit-content;
    padding: 6px 8px;

    &:hover {
      background: theme.$recurring-color7;
    }

    &-tag {
      background-color: theme.$base-color;
      width: 20px;
      text-align: center;
      border-radius: 50%;
      color: #ffffff;
      font-size: 10px;
      font-weight: 400;
      line-height: 20px;
    }
  }

  &-chips {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    margin-top: 2vh;

    &-group {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
    }

    &-chip {
      display: flex;
      align-items: center;
      width: fit-content;
      padding: 5px;
      gap: 5px;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.48), rgba(255, 255, 255, 0.48)),
        linear-gradient(0deg, #0D8390, #0D8390);
    }
  }

  &-heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2vw;
    color: theme.$header-color;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;

    &-value {
      width: 20vw;
    }

    &-AND {
      visibility: hidden;
    }

    &-action {
      display: flex;
      align-items: center;
      visibility: hidden;
      gap: 10px;
    }
  }

  &-clause {
    display: flex;
    flex-direction: row;
    gap: 2vw;
    margin-bottom: 3vh;
    align-items: center;

    &-AND {
      color: theme.$header-color;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
    }

    &-dropdown {
      width: 20vw;
    }

    &-input {
      --widthOfInput: 20vw;
      position: relative;
      display: inline-block;
      height: 34px;
      width: var(--widthOfInput);
      border: 1px solid theme.$border-color;
      margin-bottom: 5px;

      &-box {
        border: none;
        outline: none;
        padding: 5px;
        height: 23px;
        width: calc(100% - 10px);
      }
    }

    &-suggestion {
      position: fixed;
      margin: -1px;
      margin-top: 5px;
      background-color: white;
      border: 1px solid theme.$border-color;
      z-index: 100;
      max-height: 150px;
      overflow-y: auto;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      width: calc(var(--widthOfInput) - 2vw + 1.2px);
    }

    &-action {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  &-jobTitle {
    &-container {
      display: flex;
      gap: 1vw;
      justify-content: space-between;
    }
  }

  &-manager {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% - $managerCardHeight - 1vw);

    &-details {
      &-count {
        border: 1px solid theme.$base-color;
        color: theme.$base-color !important;
        width: max-content;
        padding: 0.5vh 1vh;

        &-container {
          display: flex;
          justify-content: flex-end;
          flex-direction: column;
        }
      }
    }

    &-image {
      width: $managerCardHeight;
      height: $managerCardHeight;
      line-height: $managerCardHeight;
      font-size: calc($managerCardHeight / 2);
      text-align: center;
      color: theme.$white-color;
    }

    &-arrow {
      height: 3vh;
      border: 1px solid theme.$border-color;
      width: 0;
      margin: auto;
    }

    &-container {
      display: flex;
      gap: 1vw;
    }
  }
}

.dropdownContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .dropdown {
    min-width: 15vw;
  }
}

.footer {
  display: flex;
  flex-direction: row-reverse;
  padding: 1vw 0;
  border-top: 1px solid theme.$border-color;
  gap: 10px;
}

.cancelColor {
  color: theme.$base-color;
  border: 1px solid theme.$base-color;
  box-shadow: theme.$base-color 0px 0px 0px 0.3px inset;
  background: transparent;
  font-size: 14px;
  padding: 4px 8px;

  &:hover {
    cursor: pointer;
    background: rgba(0, 0, 0, 0.08);
  }
}

.noPadding {
  padding: 0px !important;
}

.datePicker {
  width: 20vw !important;
  margin-bottom: 5px;

  .DDSDatePicker__selectPart {
    height: 34px !important;
  }
}
